.logoContainer {
  width: 100%;
  position: relative;
}

.figureLogo {
  width: 90%;
  position: relative;
  top: 1px;
  border-bottom: 1px solid #fff;
}

.figureLogo img {
  height: 5rem;
}

.logoContainer,
.figureLogo {
  display: flex;
  justify-content: center;
}
