.alreadyContainer p {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  margin-top: 6px;
  margin-bottom: 6px;
}

.alreadySignUp {
  text-decoration: none;
  color: #f118d5;
  padding-left: 10px;
  padding-right: 10px;
}
