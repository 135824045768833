.nutritionEntryMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutritionEntryTimeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutritionEntryList {
  width: 100%;
  height: 52vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutritionEntryItem {
  width: 80%;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 10px;
}

.nutritionEntryItem select {
  width: 70px;
  height: 30px;
  padding: 5px;
  border-radius: 8px;
  color: #fff;
  background-color: #2c2c2c;
  border: 1px solid #2c2c2c;
}

.nutritionEntryItem p {
  margin: 0 0 0 10px;
  width: 150px;
  text-align: left;
}

.addFood {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 1.2rem;
}

.nutritionEntryAddFood {
  width: 80%;
  margin-top: 30px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}

.addFood,
.nutritionEntryAddFood {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
}

.foodListNumber {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9510ef;
  color: #fff;
  border-radius: 50%;
  border: 1px solid #9510ef;
  font-size: 0.9rem;
}
