.signUpContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.signUpLogo {
  margin: 0 auto;
  padding-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.signUpLogo img {
  height: 6rem;
}

.signUpContainer h1 {
  font-size: 2rem;
}

.signUpForm {
  height: 70%;
  justify-content: space-between;
}

.signUpForm p {
  font-size: 1.8rem;
}

.signInButtonContainer {
  text-align: left !important;
  width: 80%;
  margin-left: 25px;
}

.signInButtonContainer,
.signInButtonContainer a {
  color: #fff;
}

.signInButtonContainer a {
  text-underline-offset: 3px;
}

.signUpFormInput input {
  width: 70%;
  padding: 10px;
  font-size: 1.2rem;
}

.signUpFormInput input {
  margin-bottom: 10px;
}

.signUpFormInput span {
  margin-left: 3.4rem;
  display: block;
  text-align: left;
  color: #ff0000;
}

.signUpAppsContainer {
  width: 80%;
  justify-content: space-evenly;
}

.signUpAppsContainer button {
  width: 9rem;
  padding: 10px;
}

.signUpAppsContainer a {
  width: 7.75rem;
  padding: 10px;
}

.signUpAppsContainer button,
.signUpAppsContainer a {
  font-size: 1.1rem;
  color: #000;
  border-width: 0;
  border-style: none;
  border-color: unset;
  border-image: none;
  background-color: #efefef;
  text-decoration: none;
  border-radius: 15px;
}

.signUpRegister {
  width: 80%;
  display: block;
  margin-bottom: 2rem;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}

/* Shared attributes */

.signUpForm,
.signUpFormInputs,
.signUpAppsContainer {
  display: flex;
}

.signUpForm,
.signUpFormInputs {
  flex-direction: column;
  align-items: center;
}

.signUpForm p {
  width: 90%;
}

.signUpFormInputs input,
.signUpAppsContainer button {
  border-radius: 15px;
}

.signUpFormInputs,
.signUpFormInput {
  width: 100%;
  margin-bottom: 10px;
}
