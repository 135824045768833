.signIpContainer {
  height: 100dvh;
  background-color: #2c2c2c;
  text-align: center;
}

.signInLogo img {
  height: 15rem;
}

.signInInput input {
  width: 80%;
  padding: 10px;
  border-radius: 15px;
  font-size: 1.2rem;
}

.signInInput input:nth-child(1) {
  margin-bottom: 10px;
}

.signInInput span {
  margin-left: 1.5rem;
  display: block;
  text-align: left;
  color: #ff0000;
}

.signInInput:nth-child(1) span {
  margin-bottom: 10px;
}

.signInButtonsContainer {
  margin-top: 20px;
}

.signInButton {
  display: block;
  width: 85%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 700;
}

.signInButtonsContainer a {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  text-underline-offset: 2px;
}

/* Shared attributes */
.signInInputsContainer,
.signInInput {
  width: 100%;
}

.signInInputsContainer,
.signInButtonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
