.goalsContainer {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.goalsLogo {
  width: 90%;
  position: relative;
  margin: 0 auto;
  top: 1px;
  border-bottom: 1px solid #fff;
  text-align: center;
}

.goalsLogo img {
  height: 5rem;
}

.goalsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.goalsParagraphs {
  margin-bottom: 30px;
}

.goalsParagraphs h1 {
  font-size: 1.9rem;
}

.goalsParagraphs p {
  margin: 0.5rem 0 0.5rem 0;
}

.inputGoalContainer {
  width: 100%;
  margin: 15px 0 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputGoalContainer label {
  font-size: 1.7rem;
}

.inputGoalContainer p {
  color: #959393;
  margin: 3px 0 3px 0;
  font-size: 0.8rem;
}

.inputGoalContainer input {
  color: #2c2c2c;
  appearance: none;
  display: -webkit-inline-flex;
  width: 75%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  border-radius: 15px;
  text-align: start;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  background-color: #fff;
}

.goalsSaveButton {
  display: block;
  width: 80%;
  margin-top: 25px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}

.errorGoalsMessage {
  color: #ff0000;
}

.backLinkContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
}

.backLink {
  position: relative;
  top: 25px;
  left: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 10px;
  margin-bottom: 10px;
}
