.habitsContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
  text-align: center;
}

.habitsContainer h2 {
  margin: 5px 0;
  font-size: 1.2rem;
}

.habitsForm {
  height: 500px;
  justify-content: space-evenly;
}

.habitsFormQuestion span {
  margin: 0.2rem 0rem 0.2rem;
  padding-left: 20px;
  display: block;
  text-align: left;
  color: #ff0000;
  font-size: 0.7rem;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

.habitsFormButtons:nth-child(3) {
  justify-content: center;
}

.hiddenButton {
  visibility: hidden;
}

.habitsFormButtons button,
.habitsFormButtonsFlex button {
  width: 38%;
  min-height: 30px;
  margin: 0 5px 8px 5px;
  padding: 10px;
  border-radius: 15px;
  font-weight: 900;
  color: #000;
}

/* Shared attributes */
.habitsContainer,
.habitsSecondContainer,
.habitsForm,
.habitsFormQuestion,
.habitsFormButtons {
  width: 100%;
}

.habitsContainer,
.habitsSecondContainer,
.habitsFormQuestion,
.habitsForm,
.habitsFormButtons:nth-child(3) {
  display: flex;
}

.habitsContainer,
.habitsSecondContainer,
.habitsFormQuestion,
.habitsForm {
  flex-direction: column;
  align-items: center;
}

.habitsFormQuestion span,
.habitsFormQuestion:nth-child(2) p {
  width: 80%;
}

.habitsFormQuestion p,
.habitsFormButtons button,
.habitsFormButtonsFlex button {
  font-size: 1rem;
}
