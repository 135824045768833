.scannerContainer {
  width: 100%;
  height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faceScannerContainer {
  position: relative;
  width: 100%;
  height: 100dvh;
  overflow: hidden;
}

.instructions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.instructions p {
  text-align: center;
  font-size: 1.2rem;
  position: absolute;
  z-index: 10;
  bottom: 3px;
  left: 8%;
}

.videoTag {
  width: 100%;
  height: 100vh;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.scannerOval {
  width: 250px;
  height: 360px;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 5;
}

.iconsContainer {
  display: none;
  width: 100%;
}

.iconsContainer p {
  width: 40px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: #495057;
  font-size: 1.2rem;
}

.instructionsLineContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: 50px;
  left: 1%;
}

.instructionsLineContainer div {
  border-right: 3px dashed #fff;
  border-top: 3px dashed #fff;
  border-bottom: 3px dashed #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.lineContainer {
  z-index: 10;
  width: 100%;
}

.lineContainer canvas {
  color: white;
}
