.unauthorizedContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #383737c4;
  z-index: 10;
}

.unauthorizedMessageContainer {
  width: 300px;
  height: 150px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #000;
  border-radius: 10px;
  color: #cdcdcd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.signUpRefresh {
  display: block;
  width: 100px;
  height: 20px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 0.9rem;
  font-weight: 600;
}
