.navbarContainer {
  height: 110px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navBarIconContainer {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.navBarIconContainer:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  height: 85px;
  width: 85px;
  border-radius: 50%;
}

.navBarIconContainer a {
  color: #fff;
}

.navBarIconContainer:nth-child(2) a {
  font-size: 2.8rem;
}

.navBarIconContainer p {
  font-size: 2rem;
}
