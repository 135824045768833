.nutritionEntryContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.logoContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.backButton {
  position: absolute;
  top: 30px;
  left: 20px;
  color: #fff;
}

.nutritionOuterContainer {
  width: 100%;
}

.nutritionEntrySelectContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.nutritionEntrySelectContainer label {
  margin-bottom: 8px;
}

.nutritionEntryContainerList {
  border: 1px solid white;
}

.nutritionEntryButtons {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nutritionEntryButtons button {
  width: 90%;
  display: block;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.2rem;
  font-weight: 600;
}

.nutritionEntryButtons button:nth-child(2) {
  margin: 30px 0 30px 0;
}

.nutritionEntrySave {
  width: 90%;
  display: block;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}
