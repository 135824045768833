.workInProgressContainer {
  height: 100dvh;
  position: relative;
  background-color: #000;
}

.workInProgressBackToHome {
  position: fixed;
  top: 30px;
  left: 40px;
}

.backLink {
  color: #fff;
}

.descriptionContainer figure img {
  width: 7rem;
  height: 7rem;
  animation: bigSmall 3s infinite linear;
}

.descriptionContainer p {
  width: 80%;
  text-align: center;
  line-height: 30px;
}

@keyframes bigSmall {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

/* Shared attributes */

.workInProgressContainer,
.descriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
