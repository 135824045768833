.scannerResultsContainer {
  height: 100dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-left: 25px;
  margin-right: 25px;
}

.title h2 {
  text-align: center;
  margin-bottom: 3px;
}

.title p {
  font-size: 0.8rem;
}

.graphsMainContainer {
  width: 100%;
  height: 72vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.graphContainer {
  width: 80%;
  height: 100%;
  margin: 15px;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 20px;
  border-radius: 15px;
}

.iconInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconInfoContainer i {
  font-size: 1.5rem;
}

.graph {
  width: 100%;
  height: 80px;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
}

.lineChartScanner {
  width: 100%;
}

.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}
