.medicationsContainer {
  position: relative;
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.medicationsForm {
  height: 500px;
  justify-content: space-evenly;
}

.medicationsTitle h2 {
  margin: 5px 0;
  font-size: 1.2rem;
}

.medicationsQuestionContainer {
  justify-content: center;
}

.medicationsQuestionContainer button {
  width: 38%;
  min-height: 30px;
  margin: 0 5px 8px 5px;
  padding: 10px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 900;
  color: #000;
}

.medicationsQuestionsContainer span {
  width: 75%;
  margin: 0.2rem 0rem 0.2rem;
  color: #ff0000;
  font-size: 0.7rem;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

.medicationsFormTextarea label {
  margin: 10px 0 25px 0;
  font-size: 1.1rem;
}

.medicationsFormTextarea textarea {
  width: 70%;
  height: 80px;
  border-radius: 15px;
  padding: 15px;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

/* Shared attributes */

.medicationsContainer,
.medicationsSecondContainer,
.medicationsForm,
.medicationsQuestionsContainer,
.medicationsQuestionContainer,
.medicationsFormTextarea {
  width: 100%;
}

.medicationsContainer,
.medicationsSecondContainer,
.medicationsForm,
.medicationsTitle,
.medicationsQuestionsContainer,
.medicationsQuestionContainer,
.medicationsFormTextarea {
  display: flex;
  align-items: center;
}

.medicationsContainer,
.medicationsSecondContainer,
.medicationsForm,
.medicationsTitle,
.medicationsQuestionsContainer,
.medicationsFormTextarea {
  flex-direction: column;
}

.medicationsQuestionsContainer span,
.medicationsFormTextarea {
  text-align: left;
}

.medicationsQuestionsContainer span,
.medicationsNextButton {
  display: block;
}
