.symptomsListContainer {
  background-color: #2c2c2c;
  position: relative;
  height: 100dvh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.titleSymptomsContainer {
  display: flex;
  align-items: center;
}

.backButton {
  position: fixed;
  top: 30px;
  left: 40px;
  color: #fff;
}

.symptomsListContainer form {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.symptomsCheckboxes {
  text-align: start;
  width: 88%;
}

.symptomCheckbox {
  position: relative;
}

.symptomCheckbox input {
  opacity: 0;
}

.symptomCheckbox input + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  line-height: 25px;
}

.symptomCheckbox input + label::before,
.symptomCheckbox input:checked + label::before,
.symptomCheckbox input:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  outline: 1.5px solid #ae2393;
  border-radius: 5px;
}

.symptomCheckbox input + label::before {
  background: #fff;
}

.symptomCheckbox input:checked + label::before {
  background: #ae2393;
}

.symptomCheckbox input:checked + label::after {
  background-image: url("/src/assets/check.svg");
  background-size: contain;
}

.symptomCheckbox label {
  width: 70%;
}

.symptomCheckbox {
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 1.2rem;
}

.symptomButton {
  width: 80%;
  display: block;
  margin-top: 10px;
  margin-bottom: 40px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}
