.modalContainer {
  width: 90px;
  height: 65px;
  padding: 5px;
  position: absolute;
  top: 30px;
  right: 20px;
  z-index: 1;
  background-color: #000;
  border: 1px solid #ec12e6;
  border-radius: 5px;
}

.modalContainer a {
  display: flex;
  padding-right: 5px;
  justify-content: flex-end;
}

.modalContainer p {
  margin: 0;
}
