.whatWeDoContainer {
  height: 100dvh;
  background-color: #2c2c2c;
  justify-content: space-between;
}

.whatWeDoLogo {
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.whatWeDoLogo img {
  height: 5rem;
}

.whatWeDoDescription h2 {
  font-size: 2.1rem;
  margin-top: 20px;
  margin-bottom: 20px;
}

.whatWeDoDescription p {
  padding-left: 50px;
  padding-right: 50px;
  font-size: 1.2rem;
}

.whatWeDoNextContainer {
  align-items: center;
  margin-bottom: 2rem;
}

.whatWeDoNext {
  display: block;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: lighter;
}

/* Shared properties */

.whatWeDoContainer,
.whatWeDoNextContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
