.inputPhysicalActivityContainer {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.inputPhysicalActivityInformation {
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputPhysicalActivityTitle {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.backButton {
  color: #fff;
  position: fixed;
  left: 20px;
  top: 40px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleContainer h2 {
  margin: 5px 0 5px 0;
}

.titleContainer p {
  width: 70%;
  margin: 0;
  font-size: 0.9rem;
}

.inputPhysicalActivities {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.activityContainer {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 10px;
  width: 40%;
}

.activityDetail input {
  width: 140px;
  height: 15px;
  padding: 5px;
  border-radius: 10px;
  color: #757575;
  background-color: #fff;
}

.activityDetail select {
  width: 153px;
  height: 25px;
  margin: 5px 0 5px 0;
  border-radius: 10px;
  border: 1px solid #757575;
  color: #757575;
  background-color: #fff;
}

.activityDetailForm button {
  padding: 5px;
  margin: 0 5px 0 5px;
  border-radius: 10px;
  color: #757575;
  background-color: #fff;
}

.activityContainer i {
  font-size: 2rem;
}

.activityContainer p {
  width: 100%;
}

.customActivityContainer {
  width: 100%;
}

.customActivityForm {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customActivityForm input[type="text"] {
  width: 80%;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 15px;
}

.customActivityForm input[type="number"] {
  width: 80%;
  margin: 10px 0 10px 0;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 15px;
}

.customActivityForm select {
  width: 85%;
  height: 45px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 15px;
  color: #757575;
}

.customActivityButtons {
  display: flex;
  margin-bottom: 25px;
}

.customActivityButtons button {
  width: 9rem;
  margin: 0 10px 20px 0;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 15px;
  color: #000;
}

.addCustomActivityContainer {
  width: 100%;
  margin: 0 20px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.addCustomActivityContainer button,
.addCustomActivityContainer a {
  width: 40%;
  margin: 20px 0 50px 0;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 15px;
  color: #000;
}

.addCustomActivityContainer a {
  display: inline-block;
  background-color: #efefef;
  text-decoration: none;
}

.physicalErrorMessage {
  color: #ff0000;
}
