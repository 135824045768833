.homeContainer {
  height: 80dvh;
}

.homeContainer h1 {
  padding-top: 5px;
  text-align: left;
}

.buttonsMainContainer {
  width: 100%;
  height: 75%;
  justify-content: space-around;
}

.buttons {
  width: 90%;
  justify-content: space-around;
  text-align: center;
}

.homeContainer div:nth-child(3) {
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonContainer {
  width: 100px;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
}

.buttonContainer:nth-child(even) {
  margin: 10px 0 10px 0;
}

.buttonContainer span {
  position: relative;
  width: 75px;
  height: 75px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2.3rem;
}

.buttonContainer span::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, #9510ef, #ec12e6) border-box;
  border: 3px solid transparent;
  border-radius: 40px;
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.resetInfoButtons button {
  margin: 0 5px 0 5px;
  padding: 10px;
  font-size: 1.1rem;
  color: #000;
  border-width: 0;
  border-style: none;
  border-color: unset;
  border-image: none;
  background-color: #efefef;
  text-decoration: none;
  border-radius: 15px;
}

/* Shared attributes */
.buttonsMainContainer,
.homeContainer,
.buttons,
.buttonContainer,
.buttonContainer span {
  display: flex;
}

.buttonsMainContainer,
.homeContainer,
.buttonContainer,
.buttonContainer span {
  flex-direction: column;
  align-items: center;
}

.buttonContainer,
.buttonContainer span {
  justify-content: center;
}

.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 15px;
}
