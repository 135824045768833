.disclaimerMainContainer {
  width: 100%;
  height: 100dvh;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.disclaimerBackToHome {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.backLink {
  position: absolute;
  left: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

.disclaimerMessageContainer {
  padding: 19.6px;
  margin: 30px 30px 20px 30px;
  color: #fff;
  text-align: center;
  background-color: #ae2393;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.disclaimerMessageContainer h2 {
  font-size: 1.4rem;
}

.disclaimerMessageContainer p {
  font-size: 1.1rem;
}

.acceptRadio {
  display: block;
  font-size: 1.1rem;
}

.acceptRadio input {
  margin-right: 10px;
}

.continueButtonActive {
  width: 80%;
  margin-top: 20px;
  padding: 5px 0 5px 0;
  display: block;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  cursor: pointer;
}

.continueButtonDeactivate {
  width: 80%;
  margin-top: 20px;
  padding: 5px 0 5px 0;
  display: block;
  text-decoration: none;
  color: #878383;
  border: 1px solid #6c6666;
  background-color: #6c6666;
  border-radius: 15px;
  cursor: none;
}
