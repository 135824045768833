.nameContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
  text-align: center;
}

.nameFormContainer {
  height: 400px;
  justify-content: space-evenly;
}

.nameFormContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.nameGreetContainer {
  margin: 0 30px;
}

.nameGreetContainer h1 {
  font-size: 1.4rem;
}

.nameGreetContainer p {
  font-size: 1.18rem;
  margin: 10px 0;
}

.nameInput {
  margin: 10px 0;
  justify-content: center;
}

.nameInput input {
  display: -webkit-inline-flex;
  width: 75%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  border-radius: 15px;
  text-align: start;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.nameInput input:focus {
  outline: 2px solid #9510ef;
}

.nameInput span {
  color: #ff0000;
  width: 80%;
  text-align: left;
}

/* Shared attributes */
.nameInput,
.nameInputContainer {
  width: 100%;
}

.nameContainer,
.nameSecondContainer,
.nameInputContainer,
.nameFormContainer,
.nameInput {
  display: flex;
  flex-direction: column;
  align-items: center;
}
