.scannerWarningContainer {
  width: 100%;
  height: 100dvh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scannerWarningContainer h1 {
  font-size: 1.5rem;
}

.scannerWarningMessage {
  padding: 19.6px;
  margin: 30px 30px 20px 30px;
  color: #fff;
  text-align: center;
  background-color: #ae2393;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scannerWarningContinue {
  display: block;
  width: 80%;
  margin-top: 20px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  padding: 10px;
}
