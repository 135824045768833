.tellCoachGoalsContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
}

.tellCoachGoalsForm {
  height: 400px;
  justify-content: space-evenly;
}

.tellCoachGoalsForm label {
  margin: 10px 0 25px 0;
  font-size: 1.1rem;
}

.tellCoachGoalsForm textarea {
  width: 70%;
  height: 80px;
  border-radius: 15px;
  padding: 15px;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

.tellCoachGoalsForm textarea:focus {
  outline: 2px solid #9510ef;
}

/* Shared attributes */
.tellCoachGoalsContainer,
.tellCoachGoalsForm {
  width: 100%;
}

.tellCoachGoalsContainer,
.tellCoachGoalsSecondContainer,
.tellCoachGoalsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tellCoachGoalsContainer,
.tellCoachGoalsForm {
  text-align: center;
}

.tellCoachGoalsForm label,
.tellCoachGoalsNextButton {
  width: 80%;
}
