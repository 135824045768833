.trackingMethodContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.trackingMethodLogo {
  margin: 0 auto;
  padding-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.trackingMethodLogo img {
  height: 5rem;
}

.trackingMethodNutrition {
  margin-top: 50px;
}

.trackingMethodButtonsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}

.trackingMethodButtonContainer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 20px;
}

.trackingMethodButtonContainer a {
  display: block;
  width: 70%;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 1.2rem;
  border-radius: 15px;
  font-weight: 900;
  color: #000;
  background-color: #efefef;
  text-decoration: none;
}

.trackingMethodButtonContainer span {
  margin: 0 10px;
}

.trackingMethodInfo {
  padding: 5px 12px;
  border: 1px solid #fff;
  border-radius: 50%;
}
