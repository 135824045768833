.displayPhysicalContainer {
  height: 100dvh;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  background-color: #2c2c2c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.displayPhysicalInnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.displayPhysicalTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.backLink {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

.displayPhysicalCaloriesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displayPhysicalCaloriesContainer div:nth-child(1) {
  text-align: left;
}

.displayPhysicalCaloriesContainer div p:nth-child(odd) {
  margin: 10px 0 10px 0;
}

.displayPhysicalCaloriesContainer div p:nth-child(2) {
  margin: 5px 0 5px 0;
  font-size: 2rem;
  font-weight: 900;
}

.displayPhysicalCaloriesContainer button {
  background: linear-gradient(to right, #9510ef, #ec12e6);
  border: none;
  border-radius: 15px;
  padding: 10px;
  font-weight: 600;
  font-size: 1.1rem;
  color: #fff;
}

.displayActivityGrid {
  width: 100%;
  height: 116px;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50% 50%;
  justify-items: center;
}

.activityInformation {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #efefef;
  border: 1px solid #fff;
  color: #000;
}

.activityInformation p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 30%;
  padding: 5px;
  /* border: 1px solid #fff; */
}

.activityInformation p span:nth-child(1) {
  font-weight: 700;
}

.stepsDisplay {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  color: #000;
  border: 1px solid #fff;
}

input[type="range"] {
  width: 90%;
  margin-top: 10px;
  overflow: hidden;
  -webkit-appearance: none;
  background-color: #636363;
  border-radius: 15px;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  -webkit-appearance: none;
  color: #9510ef;
  margin-top: -1px;
  border-radius: 50%;
}

input[type="range"]::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 15px;
  cursor: ew-resize;
  background: #9510ef;
  box-shadow: -80px 0 0 80px #9510ef;
  border-radius: 50%;
}

.stepsContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.stepsContainer p {
  margin: 5px 0 5px 0;
}

.physicalActivityChart {
  width: 100%;
  margin-top: 30px;
}

.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 15px;
}
