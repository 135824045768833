.scannerResultsContainer {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.title {
  margin-left: 25px;
  margin-right: 25px;
}

.title h2 {
  margin-bottom: 3px;
}

.title p {
  font-size: 0.8rem;
}

.buttonsMainContainer {
  width: 100%;
  height: 60%;
  margin: 0 10px 0 10px;
  justify-content: space-around;
  align-items: center;
}

.buttons {
  width: 90%;
  justify-content: space-between;
  text-align: center;
}

.buttonContainer {
  width: 125px;
  height: 90px;
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  text-decoration: none;
  background-color: #000;
  justify-content: space-between;
}

.buttonContainer p:nth-child(1) {
  font-size: 1.5rem;
  margin: 5px 5px 5px 5px;
}

.buttonContainer p:nth-child(2) {
  color: #8e9295;
  margin: 5px 0 5px 0;
}

.buttonContainer p:nth-child(3) {
  font-weight: 900;
  margin: 5px 0 5px 0;
}

/* Shared attributes */
.buttonsMainContainer,
.homeContainer,
.buttons,
.buttonContainer {
  display: flex;
}

.buttonsMainContainer,
.buttonContainer {
  flex-direction: column;
}

.buttonContainer,
.buttonContainer span {
  text-align: left;
}

.navigationButton {
  width: 80%;
  display: block;
  margin: 10px 0 10px 0;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}
