.howCanWeHelpContainer {
  height: 100dvh;
  background-color: #2c2c2c;
  justify-content: space-between;
}

.howCanWeHelpLogo {
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.howCanWeHelpLogo img {
  height: 5rem;
}

.howCanWeHelpDescription {
  padding-left: 20px;
  padding-right: 20px;
}

.howCanWeHelpDescription h2 {
  font-size: 2.1rem;
}

.howCanWeHelpDescription h3 {
  font-size: 1.1rem;
}

.howCanWeHelpNextContainer {
  align-items: center;
  margin-bottom: 2rem;
}

.howCanWeHelpNext {
  display: block;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: lighter;
}

/* Shared attributes */

.howCanWeHelpContainer,
.howCanWeHelpNextContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}
