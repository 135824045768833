.healthGoalsContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
  text-align: center;
}

.healthGoalsContainer h2 {
  width: 75%;
  margin: 20px 0;
  font-size: 1.2rem;
}

.healthGoalsForm {
  height: 500px;
  justify-content: space-around;
}

.healthGoalsForm p:nth-child(2) {
  margin-left: 35px;
  margin-right: 35px;
}

.healthGoalsCheckboxes {
  margin: 0 40px 10px 40px;
  padding: 15px;
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  text-align: left;
  font-size: 1rem;
}

.healthGoalsCheckbox {
  padding: 5px 0;
  align-items: start;
}

.healthGoalsCheckbox:nth-child(2) {
  margin: 10px 0;
}

.healthGoalsCheckbox:nth-child(3) {
  margin-bottom: 10px;
}

.healthGoalsCheckbox input {
  margin-right: 10px;
  margin-top: 3px;
  display: none;
}

.healthGoalsCheckbox input + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
}

.healthGoalsCheckbox input + label::before {
  background: #fff;
}

.healthGoalsCheckbox input:checked + label::before {
  background: #ae2393;
}

.healthGoalsCheckbox input:checked + label::after {
  background-image: url("/src/assets/check.svg");
  background-size: contain;
}

.healthGoalsOptionName {
  font-weight: 800;
}

.healthGoalsForm > span {
  width: 75%;
  margin: 0.2rem 0rem 0.2rem;
  color: #ff0000;
  text-align: left;
  font-size: 0.7rem;
}

/* Shared attributes */
.healthGoalsContainer,
.healthGoalsSecondContainer,
.healthGoalsForm,
.healthGoalsCheckbox {
  display: flex;
}

.healthGoalsContainer,
.healthGoalsSecondContainer,
.healthGoalsForm {
  flex-direction: column;
  align-items: center;
}

.healthGoalsContainer,
.healthGoalsForm {
  width: 100%;
}

.healthGoalsCheckbox input + label::before,
.healthGoalsCheckbox input:checked + label::before,
.healthGoalsCheckbox input:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  outline: 1.5px solid #ae2393;
  border-radius: 5px;
}
