.chronicConditionsContainer {
  position: relative;
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.chronicConditionsSecondContainer {
  margin-top: 10px;
}

.chronicConditionsForm {
  height: 500px;
  justify-content: space-evenly;
}

.chronicConditionsForm h2 {
  margin: 0 0;
  font-size: 1.2rem;
}

.chronicConditionsForm h3 {
  padding: 0 20px;
  font-size: 1rem;
  margin: 9px 0;
  font-weight: 300;
}

.chronicConditionsFormButtons {
  margin-bottom: 10px;
}

.chronicConditionsFormButtons button {
  width: 37%;
  margin: 0 5px;
  padding: 8px;
  border-radius: 15px;
  font-size: 1.1rem;
  font-weight: 900;
  color: #000;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

.chronicConditionsCheckboxes {
  background-color: #fff;
  color: #000;
}

.chronicConditionsCheckboxes label {
  font-size: 1.3rem;
}

.chronicConditionsCheckbox:nth-child(2n + 3) {
  padding: 9px 0;
}

.chronicConditionsCheckbox:first-child {
  padding-bottom: 9px;
}

.chronicConditionsCheckbox input {
  display: none;
}

.chronicConditionsCheckbox label {
  font-size: 1.2rem;
  width: 95%;
}

.chronicConditionsCheckbox input + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  color: #000;
}

.chronicConditionsCheckbox input + label::before {
  background: #fff;
}

.chronicConditionsCheckbox input:checked + label::before {
  background: #ae2393;
}

.chronicConditionsCheckbox input:checked + label::after {
  background-image: url("/src/assets/check.svg");
  background-size: contain;
}

/* When Illnesses are disabled */
.chronicConditionsCheckboxesDisabled {
  opacity: 0.8;
  color: #c0b9b9;
  background-color: #bbb7b7;
}

.chronicConditionsCheckboxesDisabled .chronicConditionsCheckbox input + label {
  color: #7d747c;
}

.chronicConditionsCheckboxesDisabled
  .chronicConditionsCheckbox
  input
  + label::before {
  background: #c0b9b9;
}

/* Shared attributes */
.chronicConditionsContainer,
.chronicConditionsForm,
.chronicConditionsSecondContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chronicConditionsForm,
.chronicConditionsFormButtons {
  width: 100%;
}

.chronicConditionsCheckbox input + label::before,
.chronicConditionsCheckbox input:checked + label::before,
.chronicConditionsCheckbox input:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  outline: 1.5px solid #ae2393;
  border-radius: 5px;
}

.chronicConditionsCheckboxesDisabled,
.chronicConditionsCheckboxes {
  width: 70%;
  margin: 0 20px;
  padding: 15px;
  border-radius: 15px;
  text-align: left;
  font-size: 1.4rem;
}

.chronicConditionsCheckboxesDisabled
  .chronicConditionsCheckbox
  input
  + label::before,
.chronicConditionsCheckboxesDisabled
  .chronicConditionsCheckbox
  input:checked
  + label::before,
.chronicConditionsCheckboxesDisabled
  .chronicConditionsCheckbox
  input:checked
  + label::after {
  outline: 1.5px solid #7d747c;
}

.chronicConditionsForm span,
.chronicConditionsFormButtons span {
  padding-bottom: 3px;
  text-align: left;
  width: 75%;
  margin: 0.2rem 0rem 0.2rem;
  display: block;
  text-align: left;
  color: #ff0000;
  font-size: 0.7rem;
}
