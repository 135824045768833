.basicContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
  position: relative;
}

.basicForm {
  height: 500px;
}

.basicForm h2 {
  font-size: 1.4rem;
}

.basicInputContainer input {
  width: 75%;
  appearance: none;
}

.basicInputContainer select {
  width: 80.59%;
  height: 46px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("/src/assets/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 16px auto;
}

.basicInputContainer input[type="date"] {
  height: 22px;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important;
}

.basicInputContainer input[type="date"]::-webkit-date-and-time-value {
  height: 100%;
  text-align: center;
  color: #2c2c2c;
  font-size: 1.3rem;
  text-align: start;
}

.basicInputContainer span {
  color: #ff0000;
  width: 80%;
  text-align: left;
}

/* Shared attributes */
.basicContainer,
.basicForm,
.basicInputContainer,
.basicSecondContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basicForm,
.basicInputContainer {
  justify-content: center;
}

.basicInputContainer input:focus,
.basicInputContainer input[type="date"]:focus,
.basicInputContainer select:focus {
  outline: 2px solid #9510ef;
}

.basicInputContainer input,
.basicInputContainer select {
  color: #2c2c2c;
  display: -webkit-inline-flex;
  padding: 10px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  border-radius: 15px;
  text-align: start;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  background-color: #fff;
}
