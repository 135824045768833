.foodContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
  text-align: center;
}

.foodSecondContainer {
  height: 500px;
  justify-content: space-evenly;
}

.foodFormQuestion {
  align-items: center;
}

.foodFormQuestion span {
  width: 80%;
  margin: 0.2rem 0rem 0.2rem;
  padding-left: 15px;
  display: block;
  text-align: left;
  color: #ff0000;
  font-size: 0.7rem;
}

.foodFormButtonsFlex button {
  width: 38%;
  min-height: 30px;
  margin: 0 5px 8px 5px;
  padding: 10px;
  border-radius: 15px;
  font-weight: 900;
  color: #000;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

.foodFormQuestion textarea {
  width: 70%;
  height: 80px;
  padding: 15px;
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.foodNextButton {
  width: 80%;
  display: block;
  margin: 10px 0 2px 0;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}

/* Shared attributes */
.foodContainer,
.foodSecondContainer,
.foodForm,
.foodFormQuestion {
  display: flex;
  flex-direction: column;
}

.foodContainer,
.foodSecondContainer,
.foodForm {
  align-items: center;
}

.foodContainer,
.foodSecondContainer,
.foodForm,
.foodFormQuestion,
.foodFormButtonsFlex {
  width: 100%;
}

.foodFormButtonsFlex button,
.foodFormQuestion textarea {
  font-size: 1rem;
}
