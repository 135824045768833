.notificationPermissionContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.notificationPermissionLogo {
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.notificationPermissionLogo img {
  height: 5rem;
}

.notificationPermissionContainer h2 {
  margin-top: 50px;
}

.notificationPermissionBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 30px;
  background-color: #fff;
  color: #000;
}

.notificationPermissionBox p {
  font-weight: 900;
  font-size: 1.6rem;
}

.notificationPermissionButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notificationPermissionButtons button,
.notificationPermissionButtons a {
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #ccf6ff;
  font-size: 1.3rem;
  padding: 5px;
  color: #000;
  font-weight: 900;
}

.notificationPermissionButtons a {
  text-decoration: none;
}
