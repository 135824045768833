.resourcesOptionsContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.resourcesOptionsForm {
  margin-top: 10px;
  height: 500px;
  justify-content: space-evenly;
}

.resourcesOptionsForm h2 {
  margin: 5px 0;
  font-size: 1.2rem;
}

.resourcesOptionsForm h3 {
  padding: 0 40px;
  font-size: 1.1rem;
  font-weight: 300;
}

.resourcesOptionsCheckboxes {
  width: 70%;
  margin: 0 20px 10px 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  color: #000;
  text-align: left;
  font-size: 1.4rem;
}

.resourcesOptionsCheckbox {
  padding: 5px 0;
}

.resourcesOptionsCheckbox input {
  display: none;
}

.resourcesOptionsCheckbox label {
  font-size: 1.3rem;
  width: 95%;
}

.resourcesOptionsCheckbox input + label {
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
  color: #000;
}

.resourcesOptionsCheckbox input + label::before,
.resourcesOptionsCheckbox input:checked + label::before,
.resourcesOptionsCheckbox input:checked + label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  outline: 1.5px solid #ae2393;
  border-radius: 5px;
}

.resourcesOptionsCheckbox input + label::before {
  background: #fff;
}

.resourcesOptionsCheckbox input:checked + label::before {
  background: #ae2393;
}

.resourcesOptionsCheckbox input:checked + label::after {
  background-image: url("/src/assets/check.svg");
  background-size: contain;
}

.resourcesOptionsForm span {
  width: 80%;
  margin: 0.2rem 0rem 0.2rem;
  padding-left: 10px;
  display: block;
  text-align: left;
  color: #ff0000;
  font-size: 0.7rem;
}

/* Shared Attributes */
.resourcesOptionsContainer,
.resourcesOptionsForm,
.resourcesOptionsSecondContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resourcesOptionsContainer,
.resourcesOptionsForm,
.resourcesOptionsCheckbox {
  width: 100%;
}

.resourcesOptionsCheckbox,
.resourcesOptionsCheckbox input + label {
  position: relative;
}
