.signUpOtpContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.signUpOtpLogo {
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #fff;
  width: 90%;
}

.signUpOtpLogo img {
  height: 10rem;
}

.signUpOtpForm {
  position: relative;
  height: 80%;
  margin-top: 30px;
  align-items: center;
}

.signUpOtpForm label {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.signUpOtpForm input {
  width: 75%;
  padding: 10px;
  font-size: 1.45rem;
  border-radius: 15px;
  text-align: center;
}

.signUpOtpButtons {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.signUpOtpAnchor {
  width: 100%;
  justify-content: space-evenly;
}

.signUpOtpAnchor a {
  color: #ff0000;
  text-underline-offset: 5px;
}

.signUpOtpButton {
  position: absolute;
  bottom: 62.5px;
  display: block;
  width: 85%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 8.5px;
  font-size: 1.4rem;
  font-weight: 500;
}

/* Shared attributes */
.signUpOtpForm,
.signUpOtpButtons {
  flex-direction: column;
}

.signUpOtpForm,
.signUpOtpButtons,
.signUpOtpAnchor {
  display: flex;
  align-items: center;
}
