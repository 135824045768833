.nutritionContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.firstMealContainer {
  padding-top: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.backButton {
  position: absolute;
  left: 10px;
  color: #fff;
  z-index: 10;
}

.nutritionInfoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.nutritionSlides {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.doughnutCharContainer span {
  display: block;
  margin: 20px 0 !important;
}

.doughnutContainer {
  height: 100px;
  width: 100px;
}

.nutritionSlideContainer {
  display: flex;
  flex-direction: column;
}

.nutritionChart {
  width: 100%;
}

.firstMeal {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.firstMeal,
.addFoodButton {
  display: block;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addFood {
  width: 100%;
  display: flex;
  justify-content: center;
}

.addFoodButton {
  width: 80%;
  border-radius: 15px;
}

.nutritionChart {
  width: 100%;
}

.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 20px;
}
