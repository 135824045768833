.healthAndWellnessContainer {
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.healthAndWellnessForm {
  height: 500px;
  justify-content: space-evenly;
}

.healthAndWellnessForm h2 {
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.healthAndWellnessInputContainer {
  justify-content: center;
}

.healthAndWellnessInputContainer input {
  width: 75%;
  appearance: none;
}

.healthAndWellnessInputContainer select {
  width: 80%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("/src/assets/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 16px auto;
}

.healthAndWellnessInputContainer input[type="date"] {
  height: 22px;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133)) !important;
}

.healthAndWellnessInputContainer
  input[type="date"]::-webkit-date-and-time-value {
  height: 100%;
  text-align: center;
  color: #2c2c2c;
  font-size: 1.3rem;
  text-align: start;
}

.healthAndWellnessFormButtons button {
  width: 40%;
  min-height: 30px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 900;
  color: #000;
}

.healthAndWellnessFormQuestion p {
  font-size: 1rem;
  width: 80%;
}

.healthAndWellnessFormQuestion span {
  margin: 0.2rem 0rem 0.2rem;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

/* Shared attributes */
.healthAndWellnessContainer,
.healthAndWellnessSecondContainer,
.healthAndWellnessInputContainer,
.healthAndWellnessForm,
.healthAndWellnessFormQuestion,
.healthAndWellnessFormButtons {
  width: 100%;
}

.healthAndWellnessContainer,
.healthAndWellnessSecondContainer,
.healthAndWellnessInputContainer,
.healthAndWellnessForm,
.healthAndWellnessFormQuestion {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.healthAndWellnessInputContainer input,
.healthAndWellnessInputContainer select {
  color: #2c2c2c;
  display: -webkit-inline-flex;
  padding: 10px !important;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3rem;
  border-radius: 15px;
  text-align: start;
  border: 2px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  background-color: #fff;
}

.healthAndWellnessInputContainer span,
.healthAndWellnessFormQuestion span {
  width: 80%;
  text-align: left;
  font-size: 0.7rem;
  color: #ff0000;
}

.healthAndWellnessInputContainer input:focus,
.healthAndWellnessInputContainer input[type="date"]:focus,
.healthAndWellnessInputContainer select:focus {
  outline: 2px solid #9510ef;
}
