.complexHealthContainer {
  position: relative;
  background-color: #2c2c2c;
  height: 100dvh;
  text-align: center;
}

.complexHealthForm {
  height: 500px;
  justify-content: space-evenly;
}

.complexHealthTitle h2 {
  margin: 5px 0;
  font-size: 1.2rem;
}

.complexHealthTitle p {
  width: 80%;
}

.complexHealthQuestionContainer {
  justify-content: center;
}

.complexHealthQuestionContainer button {
  width: 38%;
  min-height: 30px;
  margin: 0 5px 8px 5px;
  padding: 10px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 900;
  color: #000;
}

.notSelected {
  border: 2px solid #efefef;
}

.selected {
  border: 2px solid #9510ef;
}

.complexHealthQuestionsContainer span {
  width: 75%;
  margin: 0.2rem 0rem 0.2rem;
  display: block;
  text-align: left;
  color: #ff0000;
  font-size: 0.7rem;
}

/* Shared attributes */
.complexHealthContainer,
.complexHealthSecondContainer,
.complexHealthTitle,
.complexHealthQuestionsContainer,
.complexHealthForm {
  display: flex;
  align-items: center;
}

.complexHealthContainer,
.complexHealthSecondContainer,
.complexHealthTitle,
.complexHealthQuestionsContainer,
.complexHealthForm {
  flex-direction: column;
}

.complexHealthContainer,
.complexHealthSecondContainer,
.complexHealthForm,
.complexHealthTitle,
.complexHealthQuestionsContainer,
.complexHealthQuestionContainer {
  width: 100%;
}
