.chatbotContainer {
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.chatbotBackToHome {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.backLink {
  padding: 14px 10px 14px 10px;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 15px;
  top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #cdcdcd;
  z-index: 1;
  background-color: #000;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.responseContainer {
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  margin: 10px 10px 0 10px;
  border: 4px solid #000;
  border-radius: 15px;
  color: #cdcdcd;
  text-align: center;
}

.messagesList {
  height: 80vh;
  overflow-y: scroll;
  overflow-wrap: break-word;
  padding: 10px 10px 0 10px;
}

.messagesList p {
  margin-top: 5px;
  padding-left: 15px;
}

.messageContainer,
.messageContainerLoader {
  max-width: 300px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  height: auto;
  background-color: #cdcdcd;
  color: #000;
  padding: 5px;
  text-align: left;
  margin: 5px 0 5px 0;
}

.messageContainerLeft,
.messageContainerRight,
.messageContainerLoader {
  display: flex;
}

.messageContainerLeft {
  justify-content: left;
}

.messageContainerRight {
  justify-content: right;
}

.messageContainer:nth-child(1) {
  margin-top: 30px;
}

.messageContainer p {
  margin: 20px 0 20px 0;
  padding: 0;
  font-size: 16px;
}

.messageContainer p:nth-child(1) {
  margin-top: 5px;
  margin-bottom: 5px;
}

.messageContainerLoader {
  height: 14px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 35px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #9c9b9bbc 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }
  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }
  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }
  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

.containerRef {
  visibility: hidden;
}

.keyboardContainer {
  display: flex;
  padding: 15px;
  align-items: flex-end;
}

.keyboardContainer textarea {
  width: 90%;
  padding: 5px;
  height: max-content;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #ae2393;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.07rem;
}

.keyboardContainer textarea:focus {
  outline: none;
}

.buttonMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  width: 40px;
  height: 40px;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  border-radius: 50%;
  border: none;
  font-size: 0.8rem;
}
