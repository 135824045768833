.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
}

.logoContainer figure {
  display: flex;
  justify-content: center;
}

.logoContainer figure img {
  height: 17rem;
}

.welcomeContainer {
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  text-align: center;
}

.welcomeContainer h2 {
  width: 80%;
  padding-bottom: 20px;
  color: #ae2393;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: 2px solid #030303;
  font-size: 2rem;
}

.welcomeParagraph {
  padding-left: 65px;
  padding-right: 65px;
  font-size: 1.47rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.welcomeGetStarted {
  display: block;
  width: 80%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
}
