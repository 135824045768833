.thanksContainer {
  height: 100dvh;
  position: relative;
  background-color: #2c2c2c;
  text-align: center;
}

.thanksContainer h1 {
  margin: 5px 0;
  font-size: 1.4rem;
}

.thanksForm {
  height: 500px;
  justify-content: space-evenly;
}

.thanksForm p {
  margin: 0 50px;
  font-size: 1.6rem;
}

/* Shared attributes */
.thanksContainer,
.thanksSecondContainer,
.thanksForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
