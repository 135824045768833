@import-normalize;

body {
  margin: 0;
  background-color: #2c2c2c;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

figure {
  margin: 0;
}
