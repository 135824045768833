.nextButton {
  width: 80%;
  margin: 20px 0 10px 0;
  position: absolute;
  bottom: 10px;
  display: block;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}
