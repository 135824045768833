.symptomsContainer {
  position: relative;
  height: 100dvh;
  width: 100%;
  background-color: #2c2c2c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.backToHome {
  position: fixed;
  top: 30px;
  left: 20px;
}

.backButton {
  color: #fff;
}

.symptomsBox {
  width: 90%;
  background-color: #ae2393;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.symptomsBox h2 {
  color: #fff;
}

.symptomsBox p {
  width: 80%;
  padding: 15px;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}

.symptomsButtonContainer {
  position: fixed;
  bottom: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.symptomsButton {
  width: 80%;
  display: block;
  border-radius: 20px;
  border: none;
  text-decoration: none;
  color: #fff;
  background: linear-gradient(to right, #9510ef, #ec12e6);
  padding: 10px;
  font-size: 1.4rem;
  font-weight: 600;
}
